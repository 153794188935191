import React from 'react';
import { Layout } from '../components/shared/Layout';

const IndexPage = (): JSX.Element => {
  return (
    <Layout>
      <main className="text-center">
        <section className="bg-white py-8">
          <div className="container mx-auto">
            <h1>delaplume&apos;s</h1>
            <h2 className="mb-8 font-extralight">Ginger flavoured goodness</h2>
            <h3 className="mb-16 font-extralight text-base">
              THE HAGUE &bull; THE NETHERLANDS
            </h3>
            <img src="/img/agriculture-cooking-food-128403.jpg" />
          </div>
        </section>

        <section className="bg-white py-16">
          <div className="container mx-auto max-w-xl">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <img src="/img/winter-punch.jpg" />
              </div>
              <div>
                <p className="text-gray-600 text-base leading-loose">
                  Ginger ale has been enjoyed by people across many cultures for
                  thousands of years. People drink ginger ale for many reasons.
                  We like to believe that the most important reason is its
                  unique and delicious taste, which makes it drinkable all day
                  long.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default IndexPage;
